.App {
  text-align: center;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

.Navbar-logo {
  height: 6vmin;
  pointer-events: none;
}

.Navbar-title {
  height: 5vmin;
  pointer-events: none;
}

.App-hero {
  width: 100%;
  pointer-events: none;
}

.react-tagsinput {
  display: inline-block;
  padding: 4px 6px;
  max-width: 100%;
  line-height: 22px;
  width: 100%;
  min-height: 38px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.react-tagsinput:focus {
  border-bottom: 2px solid var(--mainBlue);
}

.react-tagsinput .react-tagsinput-tag {
  cursor: pointer;
  margin: 5px 3px 5px 0;
  position: relative;
  border-radius: 4px;
  color: var(--darkGreen);
  font-weight: normal;
  line-height: 14px;
  font-size: 14px;
  display: inline-block;
  line-height: 1.5em;
  padding: 3px 8px 3px 0.8em;
}

.react-tagsinput .react-tagsinput-tag a {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 0;
  opacity: 0;
  background-color: transparent;
  color: var(--darkGreen);
}
.react-tagsinput .react-tagsinput-tag a:before {
  content: '×';
  color: var(--greyLight);
  padding: 0 2px;
  font-weight: 900;
}
.react-tagsinput .react-tagsinput-tag:hover {
  padding-right: 26px;
}
.react-tagsinput .react-tagsinput-tag:hover a {
  opacity: 1;
  padding-right: 4px;
  background-color: transparent;
  color: var(--darkGreen);
  font-size: 24px;
}

.react-tagsinput .react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 10px;
  outline: none;
  padding: 5px;
  width: 80px;
  font-size: 18px;
  line-height: 18px;
  width: 200px;
}

.react-tagsinput .react-tagsinput-input::placeholder {
  font-size: 18px;
  line-height: 18px;
  font-family: 'Open Sans';
  color: var(--greenGrey);
}

.react-tagsinput .react-tagsinput-tag {
  transition: all 0.3s ease 0s;
  background-color: var(--blueLight);
}

.code-recording {
  color: var(--mainBlue);
}
.code-playback {
  color: var(--pink);
}
