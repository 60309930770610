:root {
  --mainBlue: #46afb7;
  --pink: #f7957e;
  --dark: #393e46;
  --greenDark: #256065;
  --darkGreen: #1a4346;
  --blueLight: #ecf7f8;
  --blueLight2: #f8fcfc;
  --greyLight: #d3dfe0;
  --greenGrey: #5f7b7e;
  --error: #eb5757;
}

body {
  margin: 0;
  font-family: 'Open Sans', 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
